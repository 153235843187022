.mat-button-base {
    margin: 8px;
}

/* Icon Button Rewrite */
[mat-icon-button].mat-mdc-icon-button {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 0;

    &.mat-mdc-button-base {
        width: 2.5rem;
        height: 2.5rem;
    }

    .mat-mdc-button-touch-target {
        width: inherit;
        height: inherit;
    }

    .mat-mdc-button-wrapper {
        display: flex;
        height: 100%;
        width: 100%;
        line-height: 1em;
        align-items: center;
        justify-content: center;
    }
}