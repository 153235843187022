body {
    table.table {
        th { white-space: nowrap; }
    
        thead,tbody {
            td,th { background-color: #FFF default; vertical-align: middle; border-bottom: 1px solid #e1e1e1; border-top-width: 0px; }
        }
    
        tr { border-color: transparent; }
    
        td {
            &.no-wrap { white-space: nowrap; }
        }
    
        tbody {
            td, th { padding: 8px 16px; }
        }
    
        border-collapse: separate !important; /* Don't collapse */
        border-spacing: 0;

        &.sticky-header {
            border-collapse: collapse;
            thead tr th { 
                position: sticky; 
                top: 0;
                z-index: 1;
            }
        }
    
    }
}