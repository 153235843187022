/* variables.scss */
@use '@angular/material' as mat;

$light-primary: mat.define-palette(mat.$indigo-palette);
$light-accent: mat.define-palette(mat.$yellow-palette, 800, 900, A400);
$light-warn: mat.define-palette(mat.$red-palette, 600, 800, A200);

$light-theme: mat.define-light-theme((
    color: (
        primary: $light-primary,
        accent: $light-accent,
        warn: $light-warn,
    ),
    // typography: mat.define-typography-config(
    //     $font-family: serif,
    // ),
));