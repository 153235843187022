mat-form-field.no-hint-area {
    .mat-mdc-form-field-subscript-wrapper {
        &::before { display: none; }
    }

    .mat-mdc-form-field-wrapper {
        padding-bottom: 0px;
        height: inherit;

        .mat-mdc-form-field-flex {
            height: inherit;
        }
    }
}

mat-form-field.no-hint-area .mat-mdc-form-field-underline {
    bottom: 0px;
}

mat-form-field {

    .mdc-text-field--filled { 
        height: auto; 
        &::before {
            height: auto;
        }

        &.mdc-text-field {
            border-top-left-radius: 0px;
            border-top-right-radius: 0px;
        }
    
    }

    .mat-mdc-form-field-flex {
        width: inherit;
    }
    .mat-mdc-form-field-infix {
        width: 100%;
    }
    .mat-mdc-input-element {
        width: 100%;
    }

    &.mat-mdc-form-field-type-mat-date-range-input {
        .mat-mdc-form-field-infix {
            width: 180px;
            max-width: 180px;
        }
        .mat-mdc-form-field-flex {
            align-items: flex-end;
        }
    }
}

/* Icon Button Rewrite */
[mat-icon-button].mat-mdc-icon-button {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 0;

    .mat-mdc-button-touch-target {
        width: inherit;
        height: inherit;
    }
}