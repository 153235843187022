$unit_size: 8px;
$sizes:(0,1,2,3,4,5,6);

// @each $name in $sizes {
//     .p-#{$name}   { padding: $name * $unit_size !important; }
//     .pl-#{$name}  { padding-left: $name * $unit_size !important; }
//     .pr-#{$name}  { padding-right: $name * $unit_size !important; }
//     .pt-#{$name}  { padding-top: $name * $unit_size !important; }
//     .pb-#{$name}  { padding-bottom: $name * $unit_size !important; }
//     .plr-#{$name} { padding-left: $name * $unit_size !important; padding-right: $name * $unit_size !important; }
//     .ptb-#{$name} { padding-top: $name * $unit_size !important; padding-bottom: $name * $unit_size !important; }

//     .m-#{$name}   { margin: $name * $unit_size !important; }
//     .ml-#{$name}  { margin-left: $name * $unit_size !important; }
//     .mr-#{$name}  { margin-right: $name * $unit_size !important; }
//     .mt-#{$name}  { margin-top: $name * $unit_size !important; }
//     .mb-#{$name}  { margin-bottom: $name * $unit_size !important; }
//     .mlr-#{$name} { margin-left: $name * $unit_size !important; margin-right: $name * $unit_size !important; }
//     .mtb-#{$name} { margin-top: $name * $unit_size !important; margin-bottom: $name * $unit_size !important; }
// }

$widths:(
    10, 20, 30, 40, 50, 60, 70, 80, 90, 100,
    33, 66,
    25, 75,
);

// @each $width in $widths {
//     .w-#{$width} { width:  $width * 1% !important; }
//     .h-#{$width} { height: $width * 1% !important; }
// }

.valign-middle { vertical-align: middle; }
.valign-top { vertical-align: top; }
.valign-bottom { vertical-align: bottom; }
.align-self-start { align-self: flex-start; }
.align-self-end { align-self: flex-end; }
.align-self-center { align-self: center; }

.position-relative { position: relative; }
.position-absolute { position: absolute; }
.position-sticky   { position: sticky; }
.position-static   { position: static; }
.absolute-centered { top: 50%; left: 50%; transform: translate(-50%, -50%); } // 絕對位置置中

.text-center { text-align: center; }
.text-left { text-align: left; }
.text-right { text-align: right; }

.img-fluid { max-width: 100%; height: auto; }
.img-avatar { width: 100%; height: 100%; border-radius: inherit; }

.color-white { color: #FFF !important; }
.color-red { color: #e53935; }
.color-green { color: #148f39; }
.color-blue { color: #205294; }
.bg-red { background-color: #e53935; }
.bg-green { background-color: #148f39; }
.bg-blue { background-color: #205294; }

.long-text { max-width: 160px; text-overflow: ellipsis !important; white-space: nowrap !important; overflow: hidden !important; }
.flex-spacer { flex: 1 1 auto; }

.radius-0 { border-radius: 0px !important; }
.radius-1 { border-radius: 4px !important; }
.radius-2 { border-radius: 8px !important; }
.radius-3 { border-radius: 12px !important; }
.radius-4 { border-radius: 16px !important; }
.radius-5 { border-radius: 20px !important; }

.text-center { text-align: center !important; }
.text-right { text-align: right !important; }
.text-left { text-align: left !important; }

body .card-panel {
    border-radius: 4px;
    background-color: #FFF;
    box-shadow: 0px 0px 2px rgba(0,0,0,0.15);
}

label { margin: 0px; }

[echarts] { min-height: 250px; }

.mat-select-filter-input 
{
    position: sticky !important;
    top: 0;
    z-index: 1;
    background-color: #FFF;
    box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.1);

    .mat-option-pseudo-checkbox 
    {
        opacity: 0;
    }
}

button.mat-icon-button {
    display: inline-flex;
    justify-content: center;
    align-items: center;
}