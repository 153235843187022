.mat-tab-body-wrapper {
    padding: 0px;
}

.mat-tab-group {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
}

.mat-tab-group .mat-tab-header {
    border-bottom-width: 1px;
}

.mat-tab-group .mat-ink-bar, .mat-tab-nav-bar .mat-ink-bar {
    height: 3px;
}

.mat-card .mat-tab-group .mat-tab-header {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    // background: rgba(0, 0, 0, 0.05);
}