table[mat-table] {
    width: 100%;
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
    background: transparent;
    border-collapse: separate;

    .mat-cell {
        padding-right: 1rem;
        // padding-left: 0.5rem;
    }

    td.mat-cell:last-of-type, 
    td.mat-footer-cell:last-of-type, 
    th.mat-header-cell:last-of-type {
        padding-right: 16px;
    }

    td.mat-cell:first-of-type, 
    td.mat-footer-cell:first-of-type, 
    th.mat-header-cell:first-of-type {
        padding-left: 16px;
    }

    .mat-table-sticky { background-color: #FFF; border-bottom: 1px solid rgba(0, 0, 0, 0.12); }
}