/* CSS Reset */
// @import '~normalize.css';
* { box-sizing: border-box; }

$breadcrumb-bg: transparent;

@import 'swiper/swiper-bundle';

@import 'bootstrap/scss/bootstrap.scss';

@tailwind base;
@tailwind components;
@tailwind utilities;

/* Material */
@import '../shared/angular-material/angular-material-theme.scss';

// app-root { background-color: transparent; }
html, body {
    height: 100%;
    margin: 0;
    background-color: #30426a;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}

/*
    THEME STYLES WITH FLEX-LAYOUT BREAKPOINTS
    breakpoint	mediaQuery
    xs	        max-width: 599px
    sm	        min-width: 600px - max-width: 959px
    md	        min-width: 960px - max-width: 1279px
    lg	        min-width: 1280px - max-width: 1919px
    xl	        min-width: 1920px - max-width: 5000px
*/

@import 'xl';

@media screen and (min-width: 1280px) and (max-width: 1919px) {
    @import 'lg';
}

@media screen and (min-width: 960px) and (max-width: 1279px) {
    @import 'md';
}

@media screen and (min-width: 600px) and (max-width: 959px) {
    @import 'sm';
}

@media screen and (max-width: 599px) {
    @import 'xs';
}

@import 'utils';

.sidenav-wrapper {
    background-color: red !important;
}
