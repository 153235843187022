mat-option.mat-mdc-option {

    padding: 0.5rem 1rem;

    &.mat-mdc-selected {
        font-weight: 600;
    }

    &.multiple-line {
        
        height: unset;

        .mat-mdc-option-text {
            white-space: normal;
        }

    }

    .mdc-list-item__primary-text {
        width: 100%;
    }

}