.mat-card-header-text { margin-left: 0px; }

mat-card { border: 1px solid rgba(0, 0, 0, 0.15); }

mat-card-content[inset] {
    margin-left: -16px;
    margin-right: -16px;
}

mat-card-content[fit-card] {
    border-radius: 4px;
}

mat-card-actions.mat-card-actions {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 8px;

    [class*="mat-button"] {
        margin: 0;
    }

}
