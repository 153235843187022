@use '@angular/material' as mat;
@include mat.core();
@import 'angular-material-variables';

@include mat.all-component-themes($light-theme);

.mat-cell .mat-icon {
    vertical-align: middle;
}

@import 'custom-angular-material-components';